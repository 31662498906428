// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";
import set_btnBorderSVGCornerRadius from "../util/set_btnBorderSVGCornerRadius.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.processSteps_writingGame", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "processSteps_writingGame"],
  end: ["PartiForm", "API", "processSteps_writingGame", "API call complete"],
  no_stepString: () => ["PartiForm", "API", "processSteps_drawingGame", "No step string provided."],
  stepNotAllowed: (step) => ["PartiForm", "API", "processSteps_drawingGame", `step not allowed: ${step}`],
  writingGameDisabled: ["PartiForm", "API", "processSteps_writingGame", "Writing Game is disabled.", "stopping step process"],
};

const possibleSteps = ["startWritingGame", "startWriting", "submitWords", "writingGameSubmitted", "viewWords"];

// ——————————————————————————————————————— API METHOD DEF. ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function processSteps_writingGame(
  DATA = {
    step: null,
  }
) {
  if (!valid_str(DATA?.step)) return this.logger("error", msgs.no_stepString(), "error", logArgs);
  if (!possibleSteps.includes(DATA?.step)) return this.logger("error", msgs.stepNotAllowed(DATA?.step), "error", logArgs);
  if (!this.options.with_writingGame) return this.logger("warning", msgs.writingGameDisabled, "warning", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { step } = DATA;
  const {
    subsections,
    writingGameWordGroups,
    writingGameSelectedWordsGroup,
    writingSubmitNotif,
    sectionFooter_writing,
    instructCards_wordCat,
    gameSectionIllus,
  } = this.ref;
  const api = this.api;
  const { is_mobile } = this.state;
  const { config_writingGame, currSubmitIndex_writingGame } = this.options;
  const { with_futureMatrix, with_writingGame } = this.options;
  const { textpartsperuser: textPartsPerUser = "all" } = config_writingGame;
  const subsection_writingGame = subsections?.find((el) => el.getAttribute("data-id") === "writingGame");
  const pillEls = Array.from(subsection_writingGame.querySelectorAll("[g-ref='pills']"));
  const gameSectionIlluEl = gameSectionIllus.find((el) => el.dataset.id === "writing");
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  switch (true) {
    ////////////// "startWritingGame" //////////////
    ////////////////////////////////////////////////

    // - reveal corresp. section...
    // - init. pill & instruct. card SVG borders...
    // - scroll to next section...
    // - ensure that page header is hidden...
    // - block fullpage scroll...
    // - set mode to "writing"...
    // - set current step to "startWriting"...
    // - init. writing game...
    case step == "startWritingGame":
      api.show_section("writing");
      if (pillEls.length > 0) this.init_pills(pillEls);
      if (!is_mobile && instructCards_wordCat?.length > 0) instructCards_wordCat.forEach((c) => set_btnBorderSVGCornerRadius(c));
      api.scrollTo_section("writing", api.set_pageBgColor("#a200ff"));
      api.hide_pageHeader();
      api.blockFullpageScroll();
      setTimeout(() => {
        this.setState({ mode: "writing", currentStep: "startWriting" });
        this.init_writingGame();
      }, 700); // ← let scroll anim. finish (to do: promise based solution)
      break;

    //////////////// "startWriting" ////////////////
    ////////////////////////////////////////////////

    // - If user is intended to submit all text parts, enable first word cat. group...
    // - If user is intended to submit only one text part, enable word cat. group corresp. to current submit indext...
    case step == "startWriting":
      if (textPartsPerUser == "all") {
        const firstWordCatGroup = writingGameWordGroups[0];
        const firstWordCatID = firstWordCatGroup.dataset.id;
        this.setState({ currentWordCat: firstWordCatID });
      } else {
        const currWordCatGroup = writingGameWordGroups[currSubmitIndex_writingGame];
        const currWordCatID = currWordCatGroup.dataset.id;
        this.setState({ currentWordCat: currWordCatID });
      }
      break;

    //////////////// "submitWords" /////////////////
    ////////////////////////////////////////////////

    // - show selected words group...
    case step == "submitWords":
      writingGameSelectedWordsGroup.setAttribute("data-is-hidden", false);
      break;

    //////////// "writingGameSubmitted" ////////////
    ////////////////////////////////////////////////

    // - hide selected words group...
    // - show submit-notif. for 2 sec. before moving to next step...
    case step == "writingGameSubmitted":
      writingGameSelectedWordsGroup.setAttribute("data-is-hidden", true);
      writingSubmitNotif.setAttribute("data-is-hidden", false);
      setTimeout(() => this.setState({ currentStep: "viewWords" }), 1100);
      break;

    ///////////////// "viewWords" //////////////////
    ////////////////////////////////////////////////

    // - on mobile: updt. mobile instruct. card text...
    // - hide submit-notif...
    // - show section footer (if Future Matrix is enabled)...
    // - fetch words...
    // - show word viewer...
    // - show game section illu...
    case step == "viewWords":
      if (is_mobile) api.updt_mobileInstructCard({ step, color_bg: "var(--color-parti-highlight)", color_text: "#000" });
      writingSubmitNotif.setAttribute("data-is-hidden", true);
      if (with_futureMatrix || with_writingGame) sectionFooter_writing.setAttribute("data-is-hidden", false);
      const newViewerWords = await api.fetch_wordViewerWords();
      api.show_wordViewer(newViewerWords);
      if (gameSectionIlluEl) gameSectionIlluEl.setAttribute("data-is-hidden", false);
      break;
  }

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
