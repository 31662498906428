// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import Marquee from "vanilla-marquee";
import { OverlayScrollbars } from "overlayscrollbars";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_widget_drag from "../eventHandlers/eh_widget_drag.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_widgets", inline: true };
const msgs = {
  start: ["init. widgets"],
  end: ["init. widgets", "init. success"],
  no_el: ["init. widgets", "no valid DOM element(s) provided"],
};

const defConfig_marquee = { startVisible: true, duplicated: true, speed: 40, gap: 0, delayBeforeStart: 1000 };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_widgets() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const {
    eventWidget_date,
    eventWidget_marquee,
    eventWidget_description,
    eventWidget_img,
    eventWidget_slideShow,
    eventWidget_location,
    eventWidget_ticketLink,
    widget_character,
  } = this.ref;
  const elements = [
    eventWidget_date,
    eventWidget_marquee,
    eventWidget_description,
    eventWidget_img,
    eventWidget_slideShow,
    eventWidget_location,
    eventWidget_ticketLink,
    widget_character,
  ];
  const { is_mobile, is_tablet_prt } = this.state;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (elements === undefined) return this.cancel_featInit(msgs.no_el);
  if (elements.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  // Prep. gsap...
  gsap.registerPlugin(Draggable);
  const dragInstConfig = { type: "x,y", edgeResistance: 0.65, inertia: true };

  // Create init. widget states...
  const widgetStates = {};

  ////////////////////////////////////// Date-widget init. ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (!is_mobile && eventWidget_date) {
    // Prep. drag. inst. config...
    const dragInstConfig_dateWidget = { ...dragInstConfig };

    // Init. widget follower...
    const { widgetFollower_date } = this.ref;
    if (widgetFollower_date)
      dragInstConfig_dateWidget.onDrag = eh_widget_drag.bind(this, eventWidget_date, widgetFollower_date, {
        followerAlign: "behind-right",
      });

    // Init drag. inst...
    const dragInst = Draggable.create(eventWidget_date, dragInstConfig_dateWidget);
    const dateWidgetState = { el: eventWidget_date, followerEl: widgetFollower_date, is_hidden: true, dragInst };
    widgetStates.dateWidget = dateWidgetState;
  }

  ///////////////////////////////////// Marquee-widget init. /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (!is_mobile && eventWidget_marquee) {
    // Init drag. inst...
    const dragInst = Draggable.create(eventWidget_marquee, dragInstConfig);

    // Init. marquee...
    const marqueeEl = eventWidget_marquee.querySelector("[data-role='marquee']");
    const marqueeInst = marqueeEl ? new Marquee(marqueeEl, defConfig_marquee) : null;

    // Init. widget state...
    const marqueeWidgetState = { el: eventWidget_marquee, is_hidden: true, dragInst, marqueeInst };
    widgetStates.marqueeWidget = marqueeWidgetState;
  }

  /////////////////////////////////// Description-widget init. ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (!is_tablet_prt && eventWidget_description) {
    // Init drag. inst...
    const dragInst = Draggable.create(eventWidget_description, dragInstConfig);
    const descriptionWidgetState = { el: eventWidget_description, is_hidden: true, dragInst };
    widgetStates.descriptionWidget = descriptionWidgetState;

    // Init. overlay scrollbar inst...
    const scrollContentWrapper = eventWidget_description.querySelector("[data-ref='scrollContentWrapper']");
    if (scrollContentWrapper) {
      const osInstance = OverlayScrollbars(scrollContentWrapper, { scrollbars: { theme: "os-theme-ash" } });
      widgetStates.descriptionWidget.osInstance = osInstance;
    }
  }

  /////////////////////////////////////// Img-widget init. ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (!is_mobile && eventWidget_img) {
    // Init drag. inst...
    const dragInst = Draggable.create(eventWidget_img, dragInstConfig);
    const imgWidgetState = { el: eventWidget_img, is_hidden: true, dragInst, imgSrc: "" };
    widgetStates.imgWidget = imgWidgetState;

    // Init. enlarge btn...
    const enlargeBtn = eventWidget_img.querySelector("[data-ref='enlargeBtn']");
    if (enlargeBtn)
      enlargeBtn.addEventListener("click", () => {
        eventWidget_img.setAttribute("data-is-large", eventWidget_img.dataset.isLarge === "false");
        enlargeBtn.setAttribute("data-is-active", eventWidget_img.dataset.isLarge);
      });
  }

  //////////////////////////////////// Slideshow-widget init. ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (!is_mobile && eventWidget_slideShow) {
    // Init drag. inst...
    const dragInst = Draggable.create(eventWidget_slideShow, dragInstConfig);

    // Init. flickity inst...
    let flickityInst = null;
    const slidesWrapper = eventWidget_slideShow.querySelector("[data-ref='slidesWrapper']");
    if (slidesWrapper) {
      flickityInst = new Flickity(slidesWrapper, {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        cellAlign: "center",
        selectedAttraction: 0.025,
        friction: 0.28,
        draggable: false,
        fade: false,
        // on: { change: (INDEX) => eh_slideChange.bind(this)(INDEX) },
      });

      // Init. slide show controls...
      const prevBtn = eventWidget_slideShow.querySelector("[g-ref='prevBtn']");
      const nextBtn = eventWidget_slideShow.querySelector("[g-ref='nextBtn']");
      if (prevBtn) prevBtn.addEventListener("click", () => flickityInst.previous());
      if (nextBtn) nextBtn.addEventListener("click", () => flickityInst.next());
    }

    // Init. widget state...
    const slideShowWidgetState = { el: eventWidget_slideShow, is_hidden: true, dragInst, flickityInst };
    widgetStates.slideShowWidget = slideShowWidgetState;
  }

  //////////////////////////////////// Location-widget init. /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (!is_mobile && eventWidget_location) {
    // Prep. drag. inst. config...
    const dragInstConfig_locationWidget = { ...dragInstConfig };

    // Init. widget follower...
    const { widgetFollower_location } = this.ref;
    if (widgetFollower_location)
      dragInstConfig_locationWidget.onDrag = eh_widget_drag.bind(this, eventWidget_location, widgetFollower_location);

    // Init drag. inst...
    const dragInst = Draggable.create(eventWidget_location, dragInstConfig_locationWidget);
    const locationWidgetState = { el: eventWidget_location, is_hidden: true, mapsLink: null, dragInst };
    widgetStates.locationWidget = locationWidgetState;
  }

  //////////////////////////////////// TicketLink-widget init. ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (eventWidget_ticketLink) {
    // Init drag. inst...
    const dragInst = Draggable.create(eventWidget_ticketLink, dragInstConfig);
    const ticketLinkWidgetState = { el: eventWidget_ticketLink, is_hidden: true, ticketLink: null, dragInst };
    widgetStates.ticketLinkWidget = ticketLinkWidgetState;
  }

  //////////////////////////////////// Character-widget init. ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (widget_character) {
    // Init drag. inst...
    const dragInst = Draggable.create(widget_character, dragInstConfig);
    const characterWidgetState = { el: widget_character, is_hidden: true, dragInst, activeIllu: Math.floor(Math.random() * 3) };
    widgetStates.characterWidget = characterWidgetState;
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.setState({ widgetStates }); // ← set init. widget states
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
