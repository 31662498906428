// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { ScrollTrigger } from "gsap/ScrollTrigger";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_scrollTrigger_consentCheck", inline: true, disabled: false };
const logArgs_wildcard = { eventName: "*", inline: true };
const msgs = {
  start: ["init. scrollTrigger_consentCheck"],
  end: ["init. scrollTrigger_consentCheck", "init. success"],
  no_sectionEl: ["no valid section el. found"],
  no_subsectionEl: ["no valid sub section el. found"],
  no_overflowEl: ["no valid overflow el. found"],
  on_enter: ["PartiForm", "scrolling into consent-check section"],
  on_enterBack: ["PartiForm", "scrolling back into consent-check section"],
  on_leave: ["PartiForm", "scrolling out of consent-check section"],
  on_leaveBack: ["PartiForm", "scrolling backwards out of consent-check section"],
  stop_mobile: ["scrollTrigger_consentCheck", "stop. on mobile"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_scrollTrigger_consentCheck() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // const api = this.api;
  const { sections, subsections } = this.ref;
  const section_intro = sections?.find((el) => el.dataset.id === "intro");
  const subsection_consentCheck = subsections?.find((el) => el.dataset.id === "consentCheck");
  const overflowWrapperEL = section_intro?.querySelector(".fp-overflow");
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(section_intro)) return this.cancel_featInit(msgs.no_sectionEl);
  if (!this.validate_refEl(subsection_consentCheck)) return this.cancel_featInit(msgs.no_subsectionEl);
  if (!this.validate_refEl(overflowWrapperEL)) return this.cancel_featInit(msgs.no_overflowEl);

  //////////////////////////////// Scroll trigger creation ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ScrollTrigger.create({
    trigger: subsection_consentCheck,
    scroller: overflowWrapperEL,
    start: "top center",
    end: "bottom top",

    ////////////////////////////// On update ///////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // onUpdate: (EVENTDATA) => {},

    ////////////////////// On enter (scrolling into) ///////////////////////
    ////////////////////////////////////////////////////////////////////////

    onEnter: () => {
      // Stop, if on mobile...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

      // Log...
      this.logger("event", msgs.on_enter, "event", logArgs_wildcard);

      // Set curr. step to 'checkConsent'...
      this.setState({ currentStep: "checkConsent" });
    },

    ///////////////////// On return (scroll back into) /////////////////////
    ////////////////////////////////////////////////////////////////////////

    onEnterBack: () => {
      // Stop, if on mobile...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

      // Log...
      this.logger("event", msgs.on_enterBack, "event", logArgs_wildcard);
    },

    ///////////////////// On leave (scrolling out of) //////////////////////
    ////////////////////////////////////////////////////////////////////////

    onLeave: () => {
      // Stop, if on mobile...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

      // Log...
      this.logger("event", msgs.on_leave, "event", logArgs_wildcard);
    },

    //////////////// On leave (scrolling backwards out of) /////////////////
    ////////////////////////////////////////////////////////////////////////

    onLeaveBack: () => {
      // Stop, if on mobile...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

      // Log...
      this.logger("event", msgs.on_leaveBack, "event", logArgs_wildcard);

      // Reset curr. step to 'intro'...
      this.setState({ currentStep: "intro" });
    },
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
