// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { OverlayScrollbars } from "overlayscrollbars";
import { Draggable } from "gsap/Draggable";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_infoDisplay", inline: true, disabled: false };
const msgs = {
  start: ["init. infoDisplay"],
  end: ["init. infoDisplay", "init. success"],
  no_el: ["init. infoDisplay", "no valid DOM el. provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_infoDisplay() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { infoDisplay } = this.ref;
  const { features } = this.state;
  const { mouse = false } = features;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(infoDisplay)) return this.cancel_featInit(msgs.no_el);

  /////////////////////////////////// Custom scroll bar init. ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Init. overlay scrollbar inst...
  const scrollContentWrapper = infoDisplay.querySelector("[data-ref='scrollContentWrapper']");
  let osInstance;
  if (scrollContentWrapper) {
    osInstance = OverlayScrollbars(scrollContentWrapper, {
      scrollbars: { theme: "os-theme-ash" },
      overflow: { x: "hidden", y: "scroll" },
    });
  }

  ///////////////////////////////////////// Drag. init. //////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // If mouse device available, init. Draggable inst...
  let dragInstance;
  if (mouse) {
    const instConfig = { type: "x,y", edgeResistance: 0.65, inertia: true };
    dragInstance = Draggable.create(infoDisplay, instConfig);
  }

  /////////////////////////////////////// Close btn. init. ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const closeBtnEl = infoDisplay.querySelector("[data-ref='closeBtn']");
  if (closeBtnEl) closeBtnEl.addEventListener("click", this.api.close_infoDisplay);

  /////////////////////////////////// Info-display-state init. ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const infoDisplayState_init = {
    text: "",
    context: "default",
    is_hidden: true,
    osInstance,
    dragInstance,
  };
  this.setState({ infoDisplayState: infoDisplayState_init });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
