// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_sections", inline: true };
const loggerMsgs = {
  start: ["init. sections"],
  end: ["init. sections", "init. success"],
  no_sectionEls: ["init. sections", "no section elements found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_sections() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { sections } = this.ref;
  this.logger("init", loggerMsgs.start, "action", logArgs);

  // Guard...
  if (sections === undefined) return this.cancel_featInit(loggerMsgs.no_sectionEls);
  if (sections.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.no_sectionEls);

  ////////////////////// Section group states init. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Set init. section states...
  const sectionStates = sections.map((el) => ({
    el,
    id: el.dataset.id,
    is_hidden: el.dataset.isHidden === "true",
  }));
  this.setState({ sectionStates });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", loggerMsgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
