// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component.js";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus.js";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger.js";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit.js";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_breakpoints from "./init/init_breakpoints.js";
import init_position from "./init/init_position.js";
import init_drag from "./init/init_drag.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_swup_enable from "./eventbusHandlers/ebh_swup_enable.js";
import ebh_swup_content_replace from "./eventbusHandlers/ebh_swup_content_replace.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_is_hidden from "./stateChangeHandlers/stChH_is_hidden.js";

// import updt_position from "./api/updt_position.js"; // DEPRECATED

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// Default log styles

const defaultLogStyles = {
  default: "#6682d6",
  action: "#c7d0ff",
  event: "#97a5ce",
  warning: "#ffaf00",
  error: "#ff3232",
  success: "#00c853",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class PrevNextPostWidget extends Component {
  ///////////////////////////////////////// Constructor //////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {};

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "PrevNextPostWidget",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
      //////
      run_withLogs: false,
      logStyles: defaultLogStyles,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_drag = init_drag.bind(this);
    this.init_breakpoints = init_breakpoints.bind(this);
    this.init_position = init_position.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      // updt_position: updt_position.bind(this), // DEPRECATED
      hideComp: () => this.setState({ is_hidden: true }),
      showComp: () => this.setState({ is_hidden: false }),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_swup_enable = ebh_swup_enable.bind(this);
    this.ebl_swup_content_replace = ebh_swup_content_replace.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_is_hidden = stChH_is_hidden.bind(this);
    this.stChL_mode = stChH_mode.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////////////////// Mount /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { eventName: "mount", inline: true });
    this.init();
  }

  /////////////////////////////////////////// Unmount ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { eventName: "unmount", inline: true });

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
    eventbus.off("swup_enable", this.ebl_swup_enable);
    eventbus.off("swup_content_replace", this.ebl_swup_content_replace);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    // eventbus.off("PrevNextPostWidget.api.updt_position", this.api.updt_position); // DEPRECATED
    eventbus.off("PrevNextPostWidget.api.hideComp", this.api.hideComp);
    eventbus.off("PrevNextPostWidget.api.showComp", this.api.showComp);
  }

  ///////////////////////////////////////////// Init. ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  init() {
    this.init_drag();
    this.setState({ mode: "init" });
    this.init_breakpoints();
    this.init_states();
    this.init_eventbus();
    this.setState({ mode: "ready" });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { eventName: "init_states", inline: true });
    this.setState({ is_hidden: this.state.is_tablet_ls || this.state.is_desktop_sm });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { eventName: "init_eventbus", inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);
    eventbus.on("swup_enable", this.ebl_swup_enable); // ← calls init_position() on init. page load
    eventbus.on("swup_content_replace", this.ebl_swup_content_replace); // ← calls init_position() after page transition

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    // eventbus.on("PrevNextPostWidget.api.updt_position", this.api.updt_position); // DEPRECATED
    eventbus.on("PrevNextPostWidget.api.hideComp", this.api.hideComp);
    eventbus.on("PrevNextPostWidget.api.showComp", this.api.showComp);
  }

  /////////////////////////////////////// State management ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    if ("is_hidden" in CHANGES) this.stChL_is_hidden(CHANGES);
    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default PrevNextPostWidget;
