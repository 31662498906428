// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeContext", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`active context: ${CHANGE}`],
  no_change: () => ["No change to state provided."],
  inval_stateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = ["default", "wirForschen", "communitySpaces", "zwischenraeume", "partizipation"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeContext(CHANGES) {
  if (!("activeContext" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.activeContext))
    return this.logger("error", msgs.inval_stateValue(CHANGES.activeContext), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { activeContext } = CHANGES;
  const {
    background,
    eventWidget_date,
    eventWidget_marquee,
    eventWidget_description,
    eventWidget_location,
    eventWidget_ticketLink,
    eventWidget_img,
    eventWidget_slideShow,
    widgetFollower_location,
    widgetFollower_date,
  } = this.ref;
  this.logger("state-change", msgs.stateChange(activeContext), "default", logArgs);

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Updt. background’s data-active-context attr...
  background.setAttribute("data-active-context", activeContext);

  // Updt. data-context attr. on widget els...
  if (eventWidget_date) eventWidget_date.setAttribute("data-context", activeContext);
  if (eventWidget_marquee) eventWidget_marquee.setAttribute("data-context", activeContext);
  if (eventWidget_description) eventWidget_description.setAttribute("data-context", activeContext);
  if (eventWidget_location) eventWidget_location.setAttribute("data-context", activeContext);
  if (eventWidget_ticketLink) eventWidget_ticketLink.setAttribute("data-context", activeContext);
  if (eventWidget_img) eventWidget_img.setAttribute("data-context", activeContext);
  if (eventWidget_slideShow) eventWidget_slideShow.setAttribute("data-context", activeContext);

  // Updt. data-context attr. on widget follower el...
  if (widgetFollower_location) widgetFollower_location.setAttribute("data-context", activeContext);
  if (widgetFollower_date) widgetFollower_date.setAttribute("data-context", activeContext);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
