// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_window_resize(ARGS) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Re-init. breakpoint-dependent states...
  this.init_breakpoints();

  // Re-init. position...
  this.init_position();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
