// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import TweenMax from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "*", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`info display state:`, CHANGE],
  no_change: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_infoDisplayState(CHANGES) {
  if (!("infoDisplayState" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { infoDisplayState } = CHANGES;
  const { infoDisplay, infoDisplay_contributorStartTag, infoDisplay_dateDisplay } = this.ref;
  this.logger("state-change", msgs.stateChange(infoDisplayState), "default", { ...logArgs, inline: false });

  ///////////////////////////////////// Info display updt. ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////// Info display text updt. ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("text" in infoDisplayState) {
    const { text, show_placeholderText = this.state.infoDisplayState.show_placeholderText } = infoDisplayState;
    const contentWrapper = infoDisplay.querySelector("[data-ref=contentWrapper]");
    const placeholderTextDisplay = infoDisplay.querySelector("[data-ref=placeholderTextDisplay]");

    // Set empty description text if no valid string...
    if (!valid_str(text)) contentWrapper.innerHTML = "";
    else {
      // Show text in placeholder display if applicable, otherwise show in text content wrapper...
      const decodedText = decodeURIComponent(text.replace(/\\u/g, "%")); // ← decode unicode characters
      if (show_placeholderText) {
        contentWrapper.innerHTML = "";
        placeholderTextDisplay.innerHTML = decodedText;
      } else {
        contentWrapper.innerHTML = decodedText;
        placeholderTextDisplay.innerHTML = "";
      }
    }
  }

  ///////////////// Info display contributor names updt. /////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("contributors" in infoDisplayState) {
    const { contributors } = infoDisplayState;
    const has_contributors = contributors && contributors.length > 0;

    // Remove all prev. contributorName divs...
    const contributorNameEls = infoDisplay.querySelectorAll("[data-ref=contributorName]");
    contributorNameEls.forEach((el) => el.remove());

    // Hide/show start tag...
    infoDisplay_contributorStartTag.setAttribute("data-is-hidden", !has_contributors);

    // Insert new contributorName divs...
    if (has_contributors) {
      // Insert a div for each name AFTER the infoDisplayContributorStartTag...
      contributors.reverse(); // ← flip order of contributors for correct rendering order
      const contributorStartTag = infoDisplay_contributorStartTag;
      contributors.forEach((name, i) => {
        const is_first = i === 0;
        const pl = is_first ? "pl-[0.12rem]" : "";
        const contributorEl = `
        <div data-ref="contributorName"
             class   ="${pl} txt-btn flex no-overflow text-[--color-txt-contributor] bg-[--color-bg-contributor]">
          <span>${name}${!is_first ? "/" : ""}</span>
        </div>`;
        contributorStartTag.insertAdjacentHTML("afterend", contributorEl);
      });
    }
  }

  /////////////////////// Info display date updt. ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("date" in infoDisplayState) {
    const { date } = infoDisplayState;
    const has_date = date !== undefined && date.length > 0;
    infoDisplay_dateDisplay.setAttribute("data-is-hidden", !has_date);
    infoDisplay_dateDisplay.innerHTML = has_date ? date : "";
  }

  ///////////////////// Info display position updt. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { position } = infoDisplayState;
  if ("position" in infoDisplayState) {
    const { x, y } = position;
    TweenMax.set(infoDisplay, { x, y });
  }

  ////////////////////// Info display colors updt. ///////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { colors } = infoDisplayState;
  if ("colors" in infoDisplayState) {
    const { bg, txt, txt_contributors, bg_contributors } = colors;
    infoDisplay.style.setProperty("--color-txt", txt);
    infoDisplay.style.setProperty("--color-bg", bg);
    infoDisplay.style.setProperty("--color-txt-contributor", txt_contributors);
    infoDisplay.style.setProperty("--color-bg-contributor", bg_contributors);
  }

  ///////////////////// Info display is-hidden updt. /////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("is_hidden" in infoDisplayState) {
    const is_hidden = infoDisplayState.is_hidden ?? true;
    infoDisplay.setAttribute("data-is-hidden", is_hidden);
  }

  /////////////////////// Info no-text illu. updt. ///////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Show/hide no-text illustration (via attr. on info display parent el.)...
  if ("show_illu_noText" in infoDisplayState) {
    const show_illu_noText = infoDisplayState.show_illu_noText ?? false;
    infoDisplay.setAttribute("data-show-illu", show_illu_noText);
  }

  /////////////////////// Placeholder text display updt. ///////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Show/hide placeholder text display (via attr. on info display parent el.)...
  if ("show_placeholderText" in infoDisplayState) {
    const show_placeholderText = infoDisplayState.show_placeholderText ?? false;
    infoDisplay.setAttribute("data-show-placeholder", show_placeholderText);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
